import textResource from '../../../../utils/text-resources/sambla/NO/messages';

const HOME_TYPES_MORTGAGE = [
  {
    label: textResource.homeTypeVilla,
    value: 'villa',
  },
  {
    label: textResource.homeTypeOwnApartment,
    value: 'ownedApartment',
  },
  {
    label: textResource.homeTypeCondominium,
    value: 'condominium',
  },
];

export default HOME_TYPES_MORTGAGE;
