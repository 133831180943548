import textResource from '../../../../utils/text-resources/sambla/NO/messages';
import submit from '../../../../mappers/NO/application';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: submit,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
    userAgreementEng: 'https://www.sambla.se/en/user-agreement/',
    userAgreement: 'https://www.sambla.fi/kaeyttoeehdot/',
    dataPolicy: 'https://www.sambla.fi/tietosuojaseloste-evaesteet/',
    dataPolicyEng: 'https://www.sambla.se/en/data-protection-policy/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'TotalAmountInput',
          props: {
            name: 'totalAmount',
            placeholder: textResource.applicationLoanAmountPlaceholder,
            label: textResource.applicationLoanAmountLabel,
            suffix: textResource.applicationLoanAmountSuffix,
            whitelist: true,
            tooltip: tooltip(textResource.applicationLoanAmountTooltip),
          },
        },
      ],
    },
  ],
};

export default data;
