export default {
  CARS_OWNED_AMOUNT: {
    required: true,
    max: 5,
  },
  CARS_OWNED_MONTHLY_COST: {
    required: true,
    isCurrency: true,
    max: 30000,
  },
  CARS_LEASED_AMOUNT: {
    required: true,
    max: 5,
  },
  CARS_LEASED_MONTHLY_COST: {
    required: true,
    isCurrency: true,
    max: 30000,
  },
};
